
input[type=text],input[type=email],input[type=phone],input[type=date],input[type=address], select, textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: .20em .20em var(--lightGray);
  }
  textarea{
    min-width: 100%;
    max-width: 100%;
  }
  
  input[type=submit] {
    width: 100%;
    background-color: var(--light);
    color: white;
    padding: 1em 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color:var(--dark)
  }
  
  input[type=submit]:hover {
    background-color: var(--dark);
    color:var(--light)
  }
  

  input[type=text]:focus ,input[type=email]:focus,input[type=phone]:focus,input[type=date]:focus,input[type=address]:focus{
    border: 2px solid;
    border-color: var(--dark)
  }
  form {
    border-radius: 5px;
    background-color: var(--dark);
    padding: 20px;
    margin-left:12%;
    margin-right:12%;
    margin-bottom:1em;
    margin-top:1em;
color: var(--light)
  }