:root{
  --dark:rgb(41, 0, 66);
  --mid:rgb(137, 0, 222);
  --light:#d5cfe1;
  --lightGray:#b7b6c1;
  --darkGray:#61616379;
  --lightGraybackground:#b7b6c1;

}


.App {
  text-align: center;
 
}
.b{
  min-height:90vh;
  background-color: var(----darkGray);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--lightGray);
}

