
.Hamburger img{
    height:1.5em;
}

@media screen and ( min-width: 768px ) {
    .Hamburger img{
        height:1.5em;
        display:none;
    }
}

