.Footer{
    background: linear-gradient(42deg, rgba(41,0,66,1) 0%, rgba(41,0,66,1) 69%, rgba(106,102,109,1) 100%);
    min-height: auto;
    position: relative;
    bottom:-22px;
    text-align: center;
    color:var(--lightGray);
    margin:auto;
    padding-left:.5em;
    padding-right:.5em;
}
.footerContainer{
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.Footer h1{
    font-family:'Courier New', Courier, monospace
}
.Footer h2{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
a:link{
    text-decoration: none;
    color:var(--light);
}
a:hover{
    text-decoration: underline;
}
a:visited {
    text-decoration: none;
    color:var(--light);
  }