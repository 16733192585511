.Service{
    text-align: center;
    padding: auto;
}
.ServiceConatiner{
    align-items: center;
    padding:2em;
}

.ServiceConatiner button{
    background-color: var(--lightgray);
    border-radius: 5%;
    border-color: var(--dark);
    padding:.5em;
    transition: padding 1s;
}

.ServiceConatiner button:hover{
    background-color: var(--dark);
    transition: padding 1s;
    padding:1em;
    border-radius: 5%;
    border-color: var(--dark);
    color:var(--lightgray)
}