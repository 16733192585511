.AboutUs{
    text-align: center;
    padding-left:10em;
    padding-right:10em;
    transition: padding 1s;
}


.infoblock{
    width:100%;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding:.5em;
}
.infoblock img{
    padding:1em;
    width:45%;
    border-radius: 3%;
}
 .upright img {
    width:40%;
    border-radius: 3%;
}

@media screen and (max-width: 900px) {
    .AboutUs{
        text-align: center;
        padding-left:2em;
        padding-right:2em;
        transition: padding 1s;
    }
    .infoblock{
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
    }
    .infoblock img{
        width:100%;
    }
    .upright img {
        width:100%;
        border-radius: 3%;
    }
}