.Menu {
    background: linear-gradient(42deg, rgba(41, 0, 66, 1) 0%, rgba(41, 0, 66, 1) 69%, rgba(106, 102, 109, 1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-right: 45px;
    padding-left: 45px;
}

.Menu img {
    height: 5em;
}

.Menu a {
    text-decoration: none;
    color: var(--lightGray);
}

.MenuPages {
    display: flex;
    flex-direction: row;
    align-content: space-around;
}

.MenuPageLink {
    margin: 5px;
}

.MenuPageLink :hover {
    color: var(--dark);
    padding: 5px;
    border-radius: 15px;
    background-color: var(--light);
    transition: background-color 1s;
    transition: border-radius .5s;

}

.Menu h1 {
    color: var(--light)
}


@media screen and (max-width: 768px) {
    .MenuPageLink {
        display: none;
        flex-direction: column;
        align-content: space-around;
    }

    .Menu img {
        height: 5em;
    }

    .Menu {
        flex-direction: column;
    }
}

@media screen and (min-width: 768px) {
    .MenuPagesMobile {
        display: none;
        width: 0%;
    }

    .Hamburger{
        display: none;
    }

}